import React from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import blogPostImg from '../images/blogPostImg.jpg'
import blogPostImg22 from '../images/pandemicera.jpg'
import { Link } from 'gatsby'
import blogPostImg2 from '../images/blogPostImg2.jpg'
import blogPostImg3 from '../images/blogPostImg3.jpg'

const BlogDetails = styled.p`
  color: gray;
`

const BlogTitle = styled.p`
  font-size: 1.25em;
  font-family: CentraNo2-Medium;
  margin-bottom: 0px !important;
  // margin-right: 4em !important;
`
const blogExcerpt = styled.p``

const GalleryWrapper = styled.div`
  margin: auto;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`

const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.25rem;
  color: #212121;
  text-align: center;
  margin: 0.5em 0;
`

const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin: 0;
`

const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`

const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`

const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`

const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`

const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`

const GivesBackWrapper = styled.div`
  margin: auto;
  padding: 5em;
  display: inline-flex;
`

const GivesBackTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  margin-bottom: 0.5em;
`

const GivesBackText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1.15em;
  line-height: 1.75em;
  font-style: italic;
`
const GivesBackText2 = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  line-height: 1.25em;
`

const ButtonLearnMore = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
`

const ButtonLearnMore2 = styled.button`
  background: #fff;
  color: #000;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
  border: 1.5px solid #000;
`

const BackSearchLink = styled.a`
  font-family: CentraNo2-Book;
  font-weight: 500;
  margin: 1em 0 2em;
  cursor: pointer;
`

export const PropManagementBlog: React.FunctionComponent = () => {
  return (
    <GivesBackWrapper className="blogPostWrapper">
      <FlexDiv style={{maxWidth: '75%', margin: 'auto'}} className="blogPostContainer">
      <FlexDiv
        style={{ flexFlow: 'column', maxWidth: '65%', marginRight: '7.5%', flex: '100' }}
        className="blogInner"
      >
        {/* <FlexDiv>
          <FlexDiv>
            <BackSearchLink className="backToBlog">
              <Link
                to="/journal"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  fontFamily: 'CentraNo2-Book',
                }}
              >
                Back to Blog
              </Link>
            </BackSearchLink>
          </FlexDiv>
          <FlexDiv style={{ placeContent: 'flex-end' }}>
            <BackSearchLink className="backToBlog">
              <Link
                to="/truecolours"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  fontFamily: 'CentraNo2-Book',
                }}
              >
                Next Post
              </Link>
            </BackSearchLink>
          </FlexDiv>

        </FlexDiv> */}
        <GivesBackTitle className="blogTitle">
          How Property Management Can Make It Through A Pandemic
        </GivesBackTitle>
        <GivesBackText2 style={{ margin: 0 }} className="dateText">
          Jul 13, 2020, 08:00am EDT
        </GivesBackText2>

        <GivesBackText className="authorBio">
          Anthony Tropea, President & Co-Founder of Ellicott Realty Group,
          overseeing the company's portfolio: Ellicott Realty, Ellicott
          Financial, & MacKenzie Hall.
        </GivesBackText>
        <img
          src={blogPostImg}
          style={{
            width: '100%',
            margin: 'auto',
            padding: '1em 0',
          }}
        />

        <GivesBackText2>
          Due to the Covid-19 pandemic, many people have been feeling elevated
          levels of financial trepidation. Unemployment rates have risen to a
          magnitude unseen since the Great Depression, and a sizable portion of
          our society has turned to their retirement savings to survive — that
          is, those who are fortunate enough to have money saved. Property
          landlords and tenants alike are among the population of people
          radically affected by the pandemic. Large numbers of tenants have
          incurred significant pay decreases due to job losses and,
          consequently, depend on the government's financial aid to make rent
          payments, if they can pay at all. The ripple effect passes to
          landlords, who carry a tremendous financial burden as they must make
          mortgage and tax payments without the rental income on which they
          depend.
        </GivesBackText2>

        <GivesBackText2>
          Landlords are commonly misperceived as wealthy real estate tyrants or
          corporate villains whose bottom line is the only item on their daily
          agenda when, in reality, this is simply untrue. The vast majority of
          landlords I know are earnest, hard-working people who are hustling in
          an attempt to get ahead.
        </GivesBackText2>

        <GivesBackText2>
          When the pandemic began, many landlords watched as the months and
          years of work and financial allocation they applied to their assets
          was now being overlooked or negated. Additionally, there was little to
          no relief coming from the media, politicians and other agencies in a
          position to provide aid to mitigate the risks these individuals had to
          navigate. Significant numbers of landlords have lost their properties
          or are in jeopardy of losing them. With rent strikes ongoing, there is
          little potential relief on the horizon from such capable parties as
          the Landlord and Tenant Board and others due to staffing decreases and
          closures to these agencies or departments.
        </GivesBackText2>

        <GivesBackText2>
          As an owner of a property management company, I endeavor to educate
          and actively assist landlords and tenants in avoiding these types of
          matters altogether, or help them through the process. With what can
          seem like countless items to complete on any given day, the importance
          of forecasting for unlikely, and often unwelcome, scenarios can fall
          by the wayside. As we have now seen, it is more important than we ever
          could have guessed. Employing measures of preparation such as rental
          rate guarantees and reserve funds can alleviate the devastation that
          unfortunate downturns in societal conditions can produce.
        </GivesBackText2>

        <GivesBackText2>
          It may surprise investors and landlords to learn that obtaining a
          considerable rent is not the most crucial factor for long-term
          stability. Rather, it's finding the best possible tenant. In many
          cases, when we assist landlords with occupying their units, our goal
          is to identify strong candidates — hard-working people who hold steady
          occupations, recent retirees or those who have excellent references
          and interview well. A preferred rental rate becomes a motivating
          incentive for exceptional candidates. This creates an amicable and
          auspicious beginning to a hopefully long-term relationship between the
          two parties, eventually increasing the potential for extended periods
          of financial stability for both landlord and tenant in contrast to
          higher tenant turnover and the associated cost fluctuations. Under
          this paradigm, landlords quickly notice reduced vacancy rates, lower
          quarterly maintenance costs due to infrequent tenant turnover and more
          sustainable profit margins over time.
        </GivesBackText2>

        <GivesBackText2>
          I know readers have heard me stress the importance of communication
          before, and I will continue to do so because it is a vital element in
          any relationship; landlords and tenants are no exception. Effective
          communication can overcome and likely eliminate so many difficult
          situations that are trivial to our businesses and relationships,
          professional or otherwise. For example, when a tenant notifies a
          landlord on the day rent is due that they will not be able to make the
          rental payment, the lack of direct candor can create undue tension. If
          landlords employ a reasonable amount of empathy and avoid jumping to
          conclusions and reacting poorly, especially during a global pandemic,
          understanding may prevail, and both parties in the situation can
          discuss solutions.{' '}
        </GivesBackText2>

        <GivesBackText2>
          I heard of a landlord who listened to the difficulties their tenants
          were having and found a viable solution to help them. It was mid-March
          when everything had first shut down and stay-at-home orders were put
          in place. People had lost their jobs and had filed for unemployment.
          Obviously, with unprecedented numbers, our government was backlogged
          in trying to provide assistance with the Canadian Emergency Response
          Benefit (CERB), and payment timelines were understandably elongated.
          April 1 was around the corner, and rents were coming due across
          Canada.
        </GivesBackText2>

        <GivesBackText2>
          This particular landlord decided to be proactive and asked their
          tenants if there would be an issue coming up with the April rent. For
          those in financial distress, the landlord used their tenants’ last
          month’s rent deposit to fill the void and give tenants another 30 days
          to generate income or develop temporary solutions together. This is a
          prime example of how communication helps resolve issues and can help
          tenants realize that a landlord is a compassionate human being, unlike
          the misperception. It will also go a long way toward a positive review
          of the landlord for future tenants to consider.
        </GivesBackText2>

        <GivesBackText2>
          We have to remember that the relationship between a landlord and a
          tenant is ultimately a relationship like any other: We can strengthen
          relationships through proper decorum, common courtesy and respect. In
          this time of great hardship, let us be mindful that we are indeed in
          this together, and commit to treating one another with the respect
          that all humankind deserves.{' '}
        </GivesBackText2>

        <GivesBackText2>
          Ellicott Realty is a fixture in its community and proud sponsor of
          Richmond Hill’s Winter Carnival and the Richmond Hill Soccer Club, the
          largest soccer organization in York Region. With a culture centred on
          client care and professionalism, our boutique realty firm is prepared
          to assist you with every stage of the real estate process.
        </GivesBackText2>
         </FlexDiv>
        <FlexDiv style={{display: 'flex', flexDirection: 'column'}}>
          <FlexDiv style={{display: 'flex', flexDirection: 'column'}}>
            <FlexDiv
              style={{
                flex: '0.8',
                flexFlow: 'column',
                paddingTop: '285px',
                marginLeft: '5%'
              }}
              className="relatedBlogPosts"
            >
          <h2 className="mobileOnly">
            Continue Reading
          </h2> 
            <div
              style={{ background: '#fff', maxWidth: '90%' }}
              className="socialCard"
            >
            <Link
              to="/pandemicera"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
              }}
              className="imgLinkWrapper"
            >
              <img
                src={blogPostImg22}
                style={{ width: '100%' }}
                className="socialImg"
              />
            </Link>
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'CentraNo2-Medium',
                          }}
                          className="relatedBlogPostsTitle"
                        >
                          The Pandemic-Era Trends Impacting Real Estate Profitability
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: 'gray',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#212121',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          In this time of great hardship, let us be mindful that we are indeed in this together, and commit to treating one another with the respect that all humankind deserves.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em' }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2021/12/28/the-pandemic-era-trends-impacting-real-estate-profitability/?sh=79d1c5a68d4a"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
              to="/truecolours"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
                paddingTop: '50px'
              }}
              className="imgLinkWrapper"
            >
              <img
                src={blogPostImg2}
                style={{ width: '100%' }}
                className="socialImg"
              />
            </Link>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'CentraNo2-Medium',
                          }}
                        >
                          It Is Time For Our True Colors To Illustrate True Leadership And Connection
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: 'gray',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/truecolours"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#212121',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          Let your true colors show in the small things and the bigger picture will always stay focused.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em' }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/05/28/it-is-time-for-our-true-colors-to-illustrate-true-leadership-and-connection/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                            className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
              to="/achievesmartgoals"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#000',
                fontFamily: 'CentraNo2-Medium',
                paddingTop: '50px'
              }}
              className="imgLinkWrapper"
            >
              <img
                src={blogPostImg3}
                style={{ width: '100%' }}
                className="socialImg"
              />
            </Link>
                  <FlexDiv style={{ flexFlow: 'column', alignSelf: 'center' }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {' '}
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#000',
                            fontFamily: 'CentraNo2-Medium',
                          }}
                        >
                          Achieve More By Setting SMART Goals
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: 'gray',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: 'none',
                            color: '#212121',
                            fontFamily: 'CentraNo2-Book',
                          }}
                        >
                          As a business owner, evaluate these key facets of your life when creating goals this year.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: '0.5em' }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/01/27/achieve-more-by-setting-smart-goals/"
                          target="_blank"
                          style={{
                            margin: '1em 0',
                            textDecoration: 'none',
                            color: 'gray',
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </FlexDiv>
          </FlexDiv>
        </FlexDiv>
        </FlexDiv>
    </GivesBackWrapper>
  )
}
