import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { PropManagementBlog } from 'components/PropManagementBlog'
import { Contact } from 'components/Contact'

const PropManagement: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <PropManagementBlog />
      <Contact />
    </EllicotLayout>
  )
}

export default PropManagement
